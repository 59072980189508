<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card
        v-if="userData && userData.id"
        class="pt-10"
      >
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="userData.avatar ? '' : 'primary'"
            :class="userData.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="userData.avatar"
              :lazy-src="require(`@/assets/images/avatars/1.png`)"
              :src="userData.avatar || require(`@/assets/images/avatars/1.png`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(userData.name) }}</span>
          </v-avatar>

          <span class="mb-2">{{ userData.name }}</span>

          <div>
            <v-icon
              v-if="$can('manage', 'all')"
              size="18"
              :color="resolveUserRoleVariant(userData.role.name)"
              class="me-2"
              @click="isBioDialogOpen = !isBioDialogOpen"
            >
              {{ icons.mdiPencil }}
            </v-icon>
            <v-chip
              v-if="userData.role"
              label
              small
              :color="resolveUserRoleVariant(userData.role.name)"
              :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(userData.role.name)}--text text-capitalize`"
            >
              {{ userData.role.display_name }}
            </v-chip>
          </div>
        </v-card-title>

        <!--        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary&#45;&#45;text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.taskDone || 125236) }}
              </h3>
              <span>{{ $t("Tâche accomplie") }}</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary&#45;&#45;text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.projectDone || 25002) }}
              </h3>
              <span>{{ $t("Projet terminé") }}</span>
            </div>
          </div>
        </v-card-text>-->

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            {{ $t("Details") }}
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              v-if="userData.user"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t("Supérieur hiérarchique") }} :</span>
              <span class="text--secondary">{{ userData.user?userData.user.name: '-' }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">{{ $t("E-mail") }} :</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t("Statut") }} :</span>
              <span class="text--secondary">
                <user-status-chip :object="userData" />
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <div>
                <div class="font-weight-medium me-2">{{ $t("Rôle") }} :</div>
                <span class="text--secondary text-capitalize">{{ userData.roles && userData.roles ? (userData.roles.map(ele => ele.display_name).join(', ')) : userData.role_name }}</span>
              </div>
            </v-list-item>
            <!--            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Tax ID:</span>
              <span class="text&#45;&#45;secondary">{{ userData.taxId }}</span>
            </v-list-item>-->

            <v-list-item
              v-if="userData.phone"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t("Téléphone") }} :</span>
              <span class="text--secondary">{{ userData.phone }}</span>
            </v-list-item>

            <v-list-item
              v-if="userData.post"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t("Poste") }} :</span>
              <span class="text--secondary">{{ userData.post }}</span>
            </v-list-item>
            <v-list-item
              v-if="userData.quota"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">{{ $t("Quota") }} :</span>
              <span class="text--secondary">{{ userData.quota }} %</span>
            </v-list-item>

            <!--            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Country:</span>
              <span class="text&#45;&#45;secondary">{{ userData.country }}</span>
            </v-list-item>-->
          </v-list>
        </v-card-text>
        <!--
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn>
          <v-btn
            color="error"
            outlined
          >
            {{ $t('Suspendu') }}
          </v-btn>
        </v-card-actions>-->
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="userData"
      ></user-bio-edit>
    </v-col>

    <!-- user plan -->
    <!--    <v-col cols="12">
      <v-card
        class="user-plan"
        flat
      >
        <v-card-title class="flex-start align-start justify-space-between pb-0">
          <v-chip
            label
            color="primary"
            small
            class="v-chip-light-bg primary&#45;&#45;text font-weight-semibold mb-3 me-3"
          >
            {{ userData.currentPlan }}
          </v-chip>

          <div class="user-pricing">
            <sup class="primary&#45;&#45;text">$</sup>
            <span class="text-5xl font-weight-semibold primary&#45;&#45;text">{{ resolveCurrentPlanValue(userData.currentPlan) }}</span>
            <sub class="text-base font-weight-light">/ month</sub>
          </div>
        </v-card-title>

        <v-card-text>
          <v-list class="pa-0">
            <v-list-item
              v-for="benefit in standardPlan.benefits"
              :key="benefit"
              dense
              class="px-0 mb-n2"
            >
              <v-icon
                size="10"
                class="me-2"
              >
                {{ icons.mdiCheckboxBlankCircle }}
              </v-icon>
              <span>{{ benefit }}</span>
            </v-list-item>
          </v-list>

          <p class="d-flex font-weight-semibold text&#45;&#45;primary mt-5 mb-2">
            <span>Days</span>
            <v-spacer></v-spacer>
            <span>26 of 30 Days</span>
          </p>
          <v-progress-linear
            value="86"
            height="8"
            rounded
          ></v-progress-linear>
          <p class="mt-2">
            4 days remaining
          </p>

          <v-btn
            color="primary"
            block
            @click.stop="$emit('update:is-plan-upgrade-dialog-visible',true)"
          >
            Upgrade Plan
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>-->
  </v-row>
</template>

<script>
import {
  mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiPencil,
} from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'
import controller from '../../user-list/useUsersList'
import UserStatusChip from '@/views/apps/user/user-list/UserStatusChip'

export default {
  components: {
    UserStatusChip,
    UserBioEdit,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { resolveUserStatusVariant, resolveUserStatusName, resolveUserRoleVariant } = controller()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      resolveUserStatusVariant,
      resolveUserStatusName,
      resolveUserRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiPencil,
      },
    }
  },
}
</script>
